import React from 'react';

import PropTypes from 'prop-types';
import { isDesktop } from 'react-device-detect';

import './index.scss';

function CurvedDashedLine({ stroke }) {
  return (
    <svg
      width="107"
      height="59"
      viewBox="0 0 107 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 56.1621H48C78.9279 56.1621 104 31.0901 104 0.162109V0.162109"
        stroke={stroke}
        strokeWidth="5"
        strokeDasharray="11 11"
      />
    </svg>
  );
}

CurvedDashedLine.propTypes = {
  stroke: PropTypes.string
};

function FicoPercentages({ ficoContent }) {
  return (
    <div className="CreditScore__tips">
      <div className="CreditScore__fico">
        <h2>FICO and Women</h2>
        <h3>
          What does FICO measure and how can I optimize my FICO score as a
          woman?
        </h3>
      </div>
      {isDesktop && (
        <h2 className="tips-header">Tips from the credit experts at Sequin</h2>
      )}
      {ficoContent.breakdownItem.map((fico, i) => {
        return (
          <div className="FicoPercentage" key={fico.id}>
            <div className="utilization">
              <span
                className="color"
                style={{
                  backgroundColor: fico.color,
                  borderRadius:
                    isDesktop &&
                    i === ficoContent.breakdownItem.length - 1 &&
                    '0px 0px 120px 0px'
                }}
              />
              <div className="line">
                <h4 style={{ backgroundColor: fico.color }}>
                  {fico.percentage}%
                </h4>
                <CurvedDashedLine stroke={fico.color} />
              </div>
            </div>
            <div className="info__mobile">
              <h1 style={{ color: fico.color }}>{fico.title}</h1>
              <h2>{fico.subtitle}</h2>
              <h3>{fico.tip}</h3>
            </div>
            <div className="info__desktop">
              <h1 style={{ color: fico.color }}>{fico.title}</h1>
              <h2>{fico.subtitle}</h2>
            </div>
            <h3 className="tip__desktop">{fico.tip}</h3>
          </div>
        );
      })}
    </div>
  );
}

FicoPercentages.propTypes = {
  ficoContent: PropTypes.shape({
    breakdownItem: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func
    }),
    length: PropTypes.number
  })
};

export default FicoPercentages;
