import React, { useState } from 'react';

import { graphql } from 'gatsby';
import { isDesktop, isMobile } from 'react-device-detect';

import FicoPercentages from '../../components/CreditCurriculum/FicoPercentages';

import '../../scss/universityTopic.scss';

const CurriculumTopic = ({ data }) => {
  let topic = data.allContentfulCurriculumTopic.nodes[0];

  const displaySlides = () => {
    if (topic.googleSlidesEmbed && isDesktop) {
      return (
        <iframe
          src={topic.googleSlidesEmbed}
          frameBorder="0"
          width="960"
          height="569"
          allowFullScreen
        ></iframe>
      );
    } else if (topic.googleSlidesEmbed && isMobile) {
      return (
        <iframe
          src={topic.googleSlidesEmbed}
          frameBorder="0"
          width="320"
          height="236"
          allowFullScreen
        ></iframe>
      );
    }
  };

  return (
    <div className="curriculum-topic">
      <div className="d-flex flex-row topic-container">
        <div className="topic-body d-flex flex-column">
          <h1 className="topic-title">{topic.title}</h1>
          <h2 className="overview-header">{topic.overviewHeader}</h2>
          {topic.creditScoreBreakdown && (
            <FicoPercentages ficoContent={topic.creditScoreBreakdown} />
          )}

          {displaySlides()}

          {topic.video && (
            <a
              href={topic.video}
              target="_blank"
              rel="noopener noreferrer"
              className="view-video"
            >
              Click here to view the video of the original event
            </a>
          )}

          {topic.introVideoEmbed && (
            <div className="container">
              <iframe
                src={`https://www.youtube.com/embed/${topic.introVideoEmbed}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={topic.title}
                className="topic-video"
              />
            </div>
          )}

          {topic.videoTranscript && (
            <p
              dangerouslySetInnerHTML={{
                __html: topic.videoTranscript.childMarkdownRemark?.html
              }}
              className="overview-body"
            ></p>
          )}

          {topic.pdfDisclaimer && (
            <p
              dangerouslySetInnerHTML={{
                __html: topic.pdfDisclaimer.childMarkdownRemark?.html
              }}
              className="pdf-disclaimer"
            ></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurriculumTopic;

export const query = graphql`
  query topicQuery($slug: String) {
    allContentfulCurriculumTopic(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        subtitle
        overviewHeader
        overviewBody {
          childMarkdownRemark {
            html
          }
        }
        videoTranscript {
          childMarkdownRemark {
            html
          }
        }
        video
        videoEmbed
        introVideoEmbed
        googleSlidesEmbed
        pdfDisclaimer {
          childMarkdownRemark {
            html
          }
        }
        creditScoreBreakdown {
          subheader
          header
          tipsSubtext
          breakdownItem {
            color
            percentage
            subtitle
            title
            tip
          }
        }
      }
    }
  }
`;
